import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { invert } from "lodash";
import useFormSubmission from "../hooks/useFormSubmission";
import { pendingFormExists } from "../queries";
import { FORM_TYPE_HTML_FRIENDLY } from "../../util/constants";

export default function GraduateFacultyNomination(props = {}) {
  console.log("function GraduateFacultyNomination props has ", props);
  const { Component, match = {} } = props;
  const { params = {}, pathname = "" } = match;
  const { formId, formType: formTypeFriendly } = params;
  const formType = invert(FORM_TYPE_HTML_FRIENDLY)[formTypeFriendly];
  const isCreate = pathname.includes("/create");
  const isEdit = pathname.includes("/edit");
  const isSubmit = pathname.includes("/submit");
  
  const form = useSelector((state) => (state?.form?.[formId]));
  const user = useSelector((state) => (state?.authentication?.user ?? {}));
  // const { academicPlan = null } = user;
  const osuid = (params?.osuid ?? user.osuid);
  const { navigateToFormLandingPage, validateEditPermitted } = useFormSubmission({ osuid });
  const [hasValidated, setValidated] = useState(false);
  const [isValidating, setValidating] = useState(true);

  const navigate = useNavigate();
  const data = useSelector((state) => (state?.person?.data ?? {}));
  // const { academicPlan = null, hasDoctoralAcademicPlan = false, osuid } = user;
  const { emplid, phone } = data;
  console.log("Forms/components/GraduateFaultyNomination emplid ", emplid, " phone ", phone);
  const validateSubmit = useCallback(async () => {  
      const hasPendingForm = await pendingFormExists({ formType, studentid: emplid }, false);
      if(hasPendingForm) return "Unable to submit Grad Faculty Nomination. There is an existing Pending or Approved Form with the Graduate School."
      return null;
  }, [formType, emplid]);

  const validateCreate = useCallback(async () => {
    // if(!academicPlan) return navigateToFormLandingPage(formTypeFriendly, "Unable to find a valid academic plan. If you are a current student, please contact your graduate office.");
    // if(!phone) return navigateToFormLandingPage(formTypeFriendly, "Unable to find a valid phone number. If you are a current student, please contact your graduate office.");
    // if(!hasDoctoralAcademicPlan) return navigateToFormLandingPage(formTypeFriendly, "We were unable to find an enrollment for a doctoral academic plan. Please contact your graduate program office.");
    const hasPendingForm = await pendingFormExists({ formType, emplid: emplid }, true);
    if(hasPendingForm) return navigateToFormLandingPage(formTypeFriendly, `There is an existing New, Pending or Approved Graduate Nomination form for ${emplid}.`);
  }, [formType, formTypeFriendly, navigateToFormLandingPage, emplid]);

 // validate create
 useEffect(() => {
  if(isCreate && hasValidated === false && isValidating === true) {
    validateCreate();
    setValidated(true);
    setValidating(false);
  }
}, [hasValidated, isCreate, isValidating, validateCreate]);

// validate edit
useEffect(() => {
  if(isEdit && hasValidated === false && isValidating === true && form) {
    if(form?.status !== "error") {
      const error = validateEditPermitted(form);
      if(error) navigateToFormLandingPage(formTypeFriendly, error);
    }
    setValidated(true);
    setValidating(false);
  }
}, [form, formTypeFriendly, hasValidated, isEdit, isValidating, navigateToFormLandingPage, validateEditPermitted]);


return (<Component {...props} isValidating={isValidating} />);
}